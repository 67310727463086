export const state = () => ({
    userAgent: null,
    userIpAddress: null,
    currentUrl: null,
});

export const mutations = {
    setUserAgent(state, userAgent) {
        state.userAgent = userAgent;
    },
    setUserIpAddress(state, userIpAddress) {
        state.userIpAddress = userIpAddress;
    },
    setCurrentUrl(state, currentUrl) {
        state.currentUrl = currentUrl;
    },
};

export const actions = {
    setUserInfo({ commit }, { userAgent, ipAddress, currentUrl }) {
        commit('setUserAgent', userAgent);
        commit('setUserIpAddress', ipAddress);
        commit('setCurrentUrl', currentUrl);
    },
};
