import query from '../queries/getCustomer.graphql';

export default async (context, inject) => {
    const auth = {};

    async function getUser() {
        try {
            const token = context.$storage.getCookie('apollo-token');
            if (!token) {
                throw Error('No Token');
            }
            const client = context.app.apolloProvider.defaultClient;
            const user = await client.query({ query, fetchPolicy: 'network-only' });
            context.store.commit('auth/set_logged', true);
            context.store.commit('auth/set_user', user.data.customer);
            context.store.dispatch('cart/setCartId', user.data.customerCart.id);
        } catch (e) {
            context.store.commit('auth/set_logged', false);
            context.store.commit('auth/set_user', {});
            context.$storage.removeCookie('apollo-token');
        }
    }

    auth.getUser = getUser;

    inject('auth', auth);
    await getUser();
};
