import gql from 'graphql-tag';
import CartListFragment from '@/queries/fragments/cartItems.gql.js';

export default gql`
    mutation addProduct($cartId: String!, $cartItemId: ID!) {
        removeItemFromCart(input: { cart_id: $cartId, cart_item_uid: $cartItemId }) {
            cart {
                id
                ...CartListFragment
            }
        }
    }
    ${CartListFragment}
`;
