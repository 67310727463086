import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=6cc5251f&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
import style0 from "./Navigation.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/~/src/modules/mobiilitukku/components/Logo.vue').default,OpusAccordionItem: require('/~/src/modules/opus/components/AccordionItem.vue').default,OpusAccordion: require('/~/src/modules/opus/components/Accordion.vue').default,OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default,Quicksearch: require('/~/src/modules/opus-ecom/components/Quicksearch.vue').default,ContentfulCategoryPromotion: require('/~/src/modules/contentful/components/Widget/CategoryPromotion.vue').default,OpusIcon: require('/~/src/modules/opus/components/Icon.vue').default,Cart: require('/~/src/modules/opus-ecom/components/Cart.vue').default})
