import gql from 'graphql-tag';

export default gql`
    mutation updateCart($cartId: String!, $items: [CartItemUpdateInput]!) {
        updateCartItems(input: { cart_id: $cartId, cart_items: $items }) {
            cart {
                id
            }
        }
    }
`;
