export const state = () => ({
    loggedIn: false,
    user: {},
});
export const mutations = {
    set_logged(state, value) {
        state.loggedIn = value;
    },
    set_user(state, value) {
        state.user = value;
    },
};
export const actions = {};
