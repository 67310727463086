//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    async fetch() {
        try {
            const page = await this.$axios.$get('/contentful/fi/footer');
            if (page.total) {
                this.banner = page.items[0];
            }
        } catch (e) {
            console.error(e);
        }
    },
    fetchOnServer: false,
    data() {
        /* No english store */
        return {
            banner: '',
            preFooterLinks: [
                {
                    title: 'Asiakaspalvelu',
                    url: '/usein-kysyttya',
                },
                {
                    title: 'Tilaus',
                    url: '/ohjeet/tilaaminen',
                },
                {
                    title: 'Palautukset',
                    url: '/vaihto-ja-palautus',
                },
                {
                    title: 'Toimitus',
                    url: '/ohjeet/toimitus',
                },
                {
                    title: 'Laitemallin tunnistus',
                    url: '/en-tieda-puhelimeni-mallia',
                },
            ],
            customerServiceFooterLinks: [
                {
                    title: 'Vaihto ja palautus',
                    url: '/vaihto-ja-palautus',
                },
                {
                    title: 'Ota yhteyttä',
                    url: '/ota-yhteytta',
                },
                {
                    title: 'Toimitusehdot',
                    url: '/toimitusehdot',
                },
                {
                    title: 'Vinkit',
                    url: '/ohjeet/vinkit',
                },
                {
                    title: 'Yritysmyynti',
                    url: '/yritysmyynti',
                },
            ],
            whatDoWeOfferFooterLinks: [
                {
                    title: 'Puhelimen kuoret',
                    url: '/puhelimet',
                },
                {
                    title: 'Näytön panssarilasit',
                    url: '/panssarilasit',
                },
                {
                    title: 'Tablet tarvikkeet',
                    url: '/tabletit',
                },
                {
                    title: 'Älykellotarvikkeet',
                    url: '/alykellotarvikkeet-ja-suojat',
                },
                {
                    title: 'Varaosat',
                    url: '/varaosat',
                },
            ],
            paymentFooterLinks: [
                {
                    title: 'Toimitusehdot',
                    url: '/toimitusehdot',
                },
                {
                    title: 'Tietosuojaseloste',
                    url: '/tietosuojaseloste',
                },
            ],
            aboutUsFooterLinks: [
                {
                    title: 'Tutustu tarinaamme',
                    url: '/mika-mobiilitukku',
                },
                {
                    title: 'Tutustu tiimiimme',
                    url: '/keita-me-olemme',
                },
                {
                    title: 'Avainlippu yritys',
                    url: '/blogi/2021/01/19/saimme-avainlippu-merkin/',
                },
                {
                    title: 'Yhteystietomme',
                    url: '/ohjeet/tietoa-yrityksesta',
                },
            ],
        };
    },
};
