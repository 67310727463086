//
//
//
//
//
//
//

export default {
    props: {
        imageUrl: {
            type: String,
            required: false,
            default: '',
        },
        filetype: {
            type: String,
            required: false,
            default: '',
        },
        imageDescription: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        videoProperties() {
            let isMuted = !this.imageDescription?.includes('audio');

            return {
                muted: isMuted,
                autoplay: isMuted,
                loop: isMuted,
                controls: !isMuted,
            };
        },
    },
};
