//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { headroom } from 'vue-headroom';
import mutation from '@/queries/logout.graphql';
import getmenu from '@/queries/topmenu.graphql';
import ClickOutside from 'vue-click-outside';
import { mapGetters } from 'vuex';

export default {
    components: {
        headroom,
    },
    directives: {
        ClickOutside,
    },
    async fetch() {
        const query = getmenu;
        // const { data: { categories } } = await this.$apollo.query({
        //     query,
        // });
        const {
            data: { categories },
        } = await this.$axios.$get('/topmenu');
        this.category = categories.items;
        this.popupItem = this.$el;

        if (this.$store.state.auth.loggedIn) {
            await this.$store.dispatch('wishlist/getWishlist');
        }
    },
    data() {
        return {
            mobileMenuOpen: false,
            isOpen: false,
            flyout: 0,
            subFlyout: 0,
            category: [],
            offset: 0,
        };
    },

    fetchKey: 'nav',
    computed: {
        ...mapGetters({
            cartOpen: 'cart/open',
        }),

        amount() {
            try {
                if (this.$store.state.cart.cart.total_quantity) {
                    return this.$store.state.cart.cart.total_quantity;
                }
                if (this.$storage.getCookie('cart_qty')) {
                    return this.$storage.getCookie('cart_qty');
                }
            } catch {
                return '0';
            }
        },
        userInitials() {
            if (this.$store.state.auth?.user?.firstname && this.$store.state.auth?.user?.lastname) {
                return `${this.$store.state.auth.user.firstname[0]}${this.$store.state.auth.user.lastname[0]}`;
            }

            return null;
        },
        wishlistSize() {
            if (this.$store.state.wishlist?.wishlist && this.$store.state.wishlist.wishlist?.items_v2?.items?.length >= 1) {
                return this.$store.state.wishlist.wishlist.items_v2.items.length;
            }

            return null;
        },
    },
    watch: {
        $route() {
            this.onClose();
        },
    },
    methods: {
        unpin() {
            try {
                const max = this.$refs.header.scrollHeight;
                const headroom = this.$refs.headroom.$el.scrollHeight;
                this.offset = max - headroom;
            } catch (e) {
                console.error('sticky offset error');
            }
        },
        onClose() {
            this.flyout = 0;
            this.mobileMenuOpen = false;
            this.subFlyout = 0;
        },
        onClick(uid) {
            this.subFlyout = 0;
            if (this.flyout == uid) {
                this.flyout = 0;
            } else {
                this.flyout = uid;
            }
        },
        onSubClick(uid) {
            this.subFlyout = uid;
        },
        async logout() {
            const res = await this.$apollo
                .mutate({
                    mutation,
                })
                .then(({ data }) => data && data.revokeCustomerToken);
            await this.$apolloHelpers.onLogout();
            await this.$store.commit('auth/set_logged', false);
            await this.$store.commit('auth/set_user', {});
            await this.$store.commit('cart/set_cart', {});
            this.$storage.setCookie('cart_qty', 0);
            this.$storage.removeCookie('cart_id');

            await this.$store.dispatch('wishlist/emptyWishlist');

            this.$router.push('/');
        },
    },
};
