//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    async fetch() {
        if (!this.banner) {
            await this.$store.dispatch('cms/fetchBanner');
        }
    },
    fetchOnServer: false,
    computed: {
        ...mapGetters({
            banner: 'cms/banner',
        }),
    },
};
