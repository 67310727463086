import gql from 'graphql-tag';

export default gql`
    query getAvailablePaymentMethods($id: String!) {
        cart(cart_id: $id) {
            available_payment_methods {
                code
                title
            }
        }
    }
`;
