const notificationDuration = 5000;
export default (context, inject) => {
    const { store, $toast } = context;
    const notify = {
        success(
            message,
            opt = {
                duration: notificationDuration,
            },
        ) {
            $toast.success(message, { ...opt });
        },
        error(
            message,
            opt = {
                duration: notificationDuration,
            },
        ) {
            $toast.error(message, { ...opt });
        },
        info(
            message,
            opt = {
                duration: notificationDuration,
            },
        ) {
            $toast.info(message, { ...opt });
        },
        clear() {
            $toast.clear();
        },
        show(
            message,
            opt = {
                duration: notificationDuration,
            },
        ) {
            $toast.show(message, { ...opt });
        },
    };
    inject('notify', notify);
    context.$notify = notify;
};
