export const state = () => ({
    banner: false,
});

export const mutations = {
    set_banner(state, banner) {
        state.banner = banner;
    },
};

export const actions = {
    async fetchBanner({ commit, state }) {
        try {
            if (!state.banner && !state.banner?.sys?.id) {
                const page = await this.$axios.$get('/contentful/fi/globalBanner');

                if (page.total) {
                    commit('set_banner', page.items[0]);
                }
            }

            return state.banner;
        } catch (e) {
            commit('set_banner', false);
        }
    },
};

export const getters = {
    banner: (state) => state.banner,
};
