//
//
//
//
//
//
//
//
//
//

import getStoreInfo from '~/queries/getStoreInfo.graphql';
export default {
    async fetch() {
        const { data } = await this.$apollo.query({
            query: getStoreInfo,
        });

        const storeInfo = {
            phone: (data && data.storeConfig && data.storeConfig.storeinfo_phone) || '',
            email: (data && data.storeConfig && data.storeConfig.storeinfo_email) || '',
            city: (data && data.storeConfig && data.storeConfig.storeinfo_city) || '',
            hours: (data && data.storeConfig && data.storeConfig.storeinfo_hours) || '',
            postcode: (data && data.storeConfig && data.storeConfig.storeinfo_postcode) || '',
            street1: (data && data.storeConfig && data.storeConfig.storeinfo_street_line1) || '',
            street2: (data && data.storeConfig && data.storeConfig.storeinfo_street_line2) || '',
            vatId: (data && data.storeConfig && data.storeConfig.storeinfo_vat_id) || '',
            customWorkingHours: (data && data.storeConfig && data.storeConfig.storeinfo_custom_workinghours) || '',
        };

        this.storeInfo = { ...storeInfo };
    },
    data() {
        return { storeInfo: {} };
    },
};
