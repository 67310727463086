const contentful = require('contentful');
// export `createClient` to use it in page components
export default function ({ $config, i18n }, inject) {
    const { CTF_SPACE_ID, CTF_CDA_ACCESS_TOKEN } = $config || process.env;
    const config = {
        space: CTF_SPACE_ID,
        accessToken: CTF_CDA_ACCESS_TOKEN,
    };
    const ctfl = contentful.createClient(config);
    inject('contentful', ctfl);
    return ctfl;
}
