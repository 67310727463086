import gql from 'graphql-tag';

export default gql`
    {
        customer {
            wishlists(pageSize: 5) {
                id
                updated_at
                items_count
                items_v2 {
                    items {
                        id
                        # eslint-disable-next-line @graphql-eslint/require-id-when-available
                        product {
                            uid
                            image {
                                label
                                url
                            }
                            name
                            price_range {
                                maximum_price {
                                    final_price {
                                        currency
                                        value
                                    }
                                }
                            }
                            media_gallery {
                                url
                            }
                            small_image {
                                url
                            }
                            sku
                            url_key
                            stock_status
                            reviews {
                                items {
                                    nickname
                                    average_rating
                                    text
                                    summary
                                    created_at
                                    ratings_breakdown {
                                        name
                                        value
                                    }
                                }
                            }
                            custom_attributes {
                                selected_attribute_options {
                                    attribute_option {
                                        is_default
                                        label
                                    }
                                }
                                attribute_metadata {
                                    code
                                }
                                entered_attribute_value {
                                    value
                                }
                            }
                            price_range {
                                minimum_price {
                                    regular_price {
                                        value
                                        currency
                                    }
                                    final_price {
                                        value
                                        currency
                                    }
                                }
                            }
                            # eslint-disable-next-line @graphql-eslint/require-id-when-available
                            ... on ConfigurableProduct {
                                # eslint-disable-next-line @graphql-eslint/require-id-when-available
                                configurable_options {
                                    attribute_code
                                    attribute_id
                                    uid
                                    label
                                    # eslint-disable-next-line @graphql-eslint/require-id-when-available
                                    values {
                                        uid
                                        default_label
                                        label
                                        store_label
                                        use_default_value
                                        value_index
                                        swatch_data {
                                            ... on ImageSwatchData {
                                                thumbnail
                                            }
                                            value
                                        }
                                    }
                                }
                                variants {
                                    attributes {
                                        code
                                        value_index
                                    }
                                    # eslint-disable-next-line @graphql-eslint/require-id-when-available
                                    product {
                                        uid
                                        # eslint-disable-next-line @graphql-eslint/require-id-when-available
                                        media_gallery_entries {
                                            uid
                                            disabled
                                            file
                                            label
                                            position
                                        }
                                        sku
                                        stock_status
                                        custom_attributes {
                                            selected_attribute_options {
                                                attribute_option {
                                                    is_default
                                                    label
                                                }
                                            }
                                            attribute_metadata {
                                                code
                                            }
                                            entered_attribute_value {
                                                value
                                            }
                                        }
                                        reviews {
                                            items {
                                                nickname
                                                average_rating
                                                text
                                                summary
                                                created_at
                                                ratings_breakdown {
                                                    name
                                                    value
                                                }
                                            }
                                        }
                                        price_range {
                                            minimum_price {
                                                regular_price {
                                                    value
                                                    currency
                                                }
                                                final_price {
                                                    value
                                                    currency
                                                }
                                            }
                                        }
                                        price {
                                            regularPrice {
                                                amount {
                                                    currency
                                                    value
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ... on ConfigurableWishlistItem {
                            configurable_options {
                                id
                                option_label
                                value_id
                                value_label
                            }
                        }
                    }
                }
            }
        }
    }
`;
