//
//
//
//
//

export default {
    props: {
        theme: {
            type: String,
            required: false,
            default: 'info',
            validator(value) {
                return ['info', 'new', 'sale'].indexOf(value) !== -1;
            },
        },
    },
    computed: {
        themeClass() {
            switch (this.theme) {
                case 'new':
                    return 'bg-success text-white';
                case 'sale':
                    return 'bg-error text-white';
                default:
                    return 'bg-info text-white';
            }
        },
    },
};
