import { render, staticRenderFns } from "./CategoryPromotion.vue?vue&type=template&id=6dfb76ba&"
import script from "./CategoryPromotion.vue?vue&type=script&lang=js&"
export * from "./CategoryPromotion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductLabel: require('/~/src/modules/opus-ecom/components/ProductLabel.vue').default,ContentfulVideo: require('/~/src/modules/contentful/components/ContentfulVideo.vue').default,OpusLink: require('/~/src/modules/opus/components/Link.vue').default,OpusIcon: require('/~/src/modules/opus/components/Icon.vue').default})
