import gql from 'graphql-tag';
import CartListFragment from '@/queries/fragments/cartItems.gql.js';

export default gql`
    mutation setAdditionalDataOnCart($input: SetAdditionalDataToCartInput) {
        setAdditionalDataToCart(input: $input) {
            cart {
                id
                ...CartListFragment
            }
        }
    }
    ${CartListFragment}
`;
