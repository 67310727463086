export const state = () => ({
    updateCount: 0,
});
export const mutations = {
    increment(state) {
        state.updateCount++;
    },
};
export const actions = {
    update({ commit }) {
        commit('increment');
    },
};
