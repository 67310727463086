import gql from 'graphql-tag';

export default gql`
    fragment CartListFragment on Cart {
        id
        total_quantity
        email
        applied_coupons {
            code
        }
        prices {
            grand_total {
                value
                currency
            }
            applied_taxes {
                label
                amount {
                    value
                    currency
                }
            }
            discounts {
                label
                amount {
                    value
                    currency
                }
            }
            subtotal_including_tax {
                currency
                value
            }
        }
        billing_address {
            firstname
            lastname
            country {
                code
                label
            }
            company
            postcode
            street
            city
            telephone
        }
        shipping_addresses {
            selected_shipping_method {
                carrier_code
                method_code
                amount {
                    value
                    currency
                }
            }
            firstname
            lastname
            country {
                code
                label
            }
            company
            postcode
            street
            city
            telephone
            available_shipping_methods {
                markup_type
                method_title
                carrier_code
                carrier_title
                method_code
                amount {
                    currency
                    value
                }
            }
        }
        available_payment_methods {
            code
            title
        }
        selected_payment_method {
            code
            title
            purchase_order_number
        }
        items {
            uid
            product {
                uid
                sku
                name
                url_key
                thumbnail {
                    url
                    label
                }
                price_tiers {
                    quantity
                    final_price {
                        value
                        currency
                    }
                }
                price_range {
                    minimum_price {
                        regular_price {
                            value
                            currency
                        }
                        final_price {
                            value
                            currency
                        }
                    }
                }
                upsell_products {
                    sku
                }
                custom_attributes {
                    selected_attribute_options {
                        attribute_option {
                            is_default
                            label
                        }
                    }
                    attribute_metadata {
                        code
                    }
                    entered_attribute_value {
                        value
                    }
                }
                stock_status
                ... on ConfigurableProduct {
                    variants {
                        attributes {
                            uid
                        }
                        product {
                            price_range {
                                minimum_price {
                                    regular_price {
                                        value
                                    }
                                    final_price {
                                        value
                                    }
                                }
                            }
                            url_key
                            uid
                            thumbnail {
                                url
                            }
                        }
                    }
                }
            }
            prices {
                price_including_tax {
                    value
                    currency
                }
                row_total_including_tax {
                    currency
                    value
                }
                discounts {
                    amount {
                        value
                        currency
                    }
                }
                price {
                    currency
                    value
                }
            }
            quantity
            ... on ConfigurableCartItem {
                configured_variant {
                    thumbnail {
                        url
                    }
                }
                product {
                    url_key
                    thumbnail {
                        url
                    }
                }
                configurable_options {
                    configurable_product_option_uid
                    option_label
                    configurable_product_option_value_uid
                    value_label
                }
            }
        }
    }
`;
