import gql from 'graphql-tag';

export default gql`
    mutation sendMetaEventData($data: EventDataInput!) {
        sendEventData(input: $data) {
            success
            error_message
            api_data {
                contents {
                    id
                    item_price
                    quantity
                }
                content_type
                currency
                value
                content_ids
            }
        }
    }
`;
