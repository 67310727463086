//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['item', 'disableEdit', 'disable'],
    data() {
        return {
            qty: this.item.quantity,
        };
    },
    computed: {
        tierPrice() {
            if (this.item?.product && this.item?.product?.price_tiers) {
                const [tier] = this.item.product.price_tiers.filter((price) => price.quantity > this.item.quantity);
                if (tier) {
                    const regularPrice = this.item.product.price_range.minimum_price.regular_price.value;
                    const discountPercent = Math.round(((regularPrice - tier.final_price.value) / regularPrice) * 100);

                    return this.$t('cart.tier_price', [tier.quantity, discountPercent]);
                }
                return null;
            }
        },
        discountPrice() {
            if (this.item?.product && this.item.product.price_range.minimum_price.regular_price.value > this.item.prices.price_including_tax.value) {
                return this.item.prices.price_including_tax.value;
            }
            return false;
        },
        discountPercent() {
            if (this.item?.product && this.item.product.price_range.minimum_price.regular_price.value > this.item.prices.price_including_tax.value) {
                return 100 - ((this.item.prices.price_including_tax.value / this.item.product.price_range.minimum_price.regular_price.value) * 100).toFixed(0);
            }
            return false;
        },
        brand() {
            if (this.item && this.item.product) {
                try {
                    const value = this.item.product.custom_attributes.find((attribute) => attribute.attribute_metadata.code === 'brand');
                    return value.selected_attribute_options.attribute_option[0].label;
                } catch (e) {}
            }
        },
    },
    methods: {
        async update(uid) {
            try {
                this.disable = true;
                const { cart } = await this.$store.dispatch('cart/update', {
                    items: [
                        {
                            cart_item_uid: uid,
                            quantity: this.qty,
                        },
                    ],
                });
            } catch (e) {
                if (e.message.includes('The requested qty is not available')) {
                    this.$notify.error('Tuotetta ei valitettavasti ole tällä hetkellä pyydettävää määrää');
                } else {
                    this.$notify.error(e);
                }
            } finally {
                this.disable = false;
            }
        },
        async remove(uid) {
            const { cart } = await this.$store.dispatch('cart/removeProduct', {
                uid,
                product: this.item,
            });
        },
    },
};
