//
//
//
//
//

export default {
    props: {
        exlusive: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            asd: this.exlusive,
        };
    },
};
