//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cmsUrls from '~/mixins/cmsUrls';

export default {
    props: ['category'],
    mixins: [cmsUrls],
    async fetch() {
        try {
            if (this.category) {
                const locale = 'fi';
                const page = await this.$axios.$get(`/categorypromotion/${locale}/${this.category.id}`);
                this.promotions = page.items;
            }
        } catch (e) {
            console.error(e);
            this.promotions = [];
        }
    },
    data() {
        return {
            promotions: [],
        };
    },
};
