export default function ({ $axios, app, store, $auth, $i18n }) {
    // Fix for running in Google Cloud Run
    $axios.defaults.baseURL = `${app.$config.HOSTNAME}/api`;
    $axios.onRequest((config) => {
        let group = '';
        try {
            if (store.state.auth.user) {
                group = store.state.auth.user.tukku_club.group_info.customer_group;
            }
        } catch (e) {
            group = 'empty';
        }
        if (config.headers.common) {
            config.headers.common['Group'] = group || '';
            config.headers.common['Apollo-Token'] = app.$storage.getCookie('apollo-token') || '';
        }
    });
}
