//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import query from '@/queries/quicksearch.graphql';
import categoryQuery from '@/queries/quicksearchCategory.graphql';
import debounce from 'lodash.debounce';
import ClickOutside from 'vue-click-outside';

export default {
    directives: {
        ClickOutside,
    },
    props: ['max', 'label'],
    async fetch() {
        this.products = [];
        this.categories = [];
        if (this.categorySearchMinLength === null || this.categorySearchMaxLength === null) {
            const defaultMin = 3;
            const defaultMax = 128;
            const {
                data: { storeConfig },
            } = await this.$axios.$get(`/config`);
            this.categorySearchMinLength = parseInt(storeConfig.catalog_search_min_query_length) || defaultMin;
            this.categorySearchMaxLength = parseInt(storeConfig.catalog_search_max_query_length) || defaultMax;
        }
        if (this.search.length >= this.productSearchMinLength) {
            const {
                data: {
                    products: { items },
                },
            } = await this.$apollo.query({
                query,
                variables: { input: this.search, pageSize: this.max },
            });
            this.products = items;
            if (this.search.length >= this.categorySearchMinLength && this.search.length <= this.categorySearchMaxLength) {
                const {
                    data: { categoryList },
                } = await this.$apollo.query({
                    query: categoryQuery,
                    variables: { input: this.search, pageSize: this.max },
                });
                this.categories = categoryList.slice(0, 6);
            }
        }
    },
    fetchKey: 'quicksearch',
    data() {
        return {
            productSearchMinLength: 1,
            categorySearchMinLength: null,
            categorySearchMaxLength: null,
            search: '',
            products: [],
            categories: [],
            articles: [
                // 'Nää menee vihreeks hoverilla',
                // 'Muista se!',
                // 'Tässä max 5',
            ],
        };
    },
    watch: {
        $route() {
            this.products = [];
            this.search = '';
        },
    },
    methods: {
        process() {
            this.products = [];
            this.categories = [];
            const debouncedQuery = debounce(this.$fetch, 500);
            debouncedQuery();
        },
        onSubmit() {
            this.$root.$emit('quicksearch', this.search);
            this.$router.push(this.localeRoute({ name: 'search', query: { q: this.search } }));
        },
        onClose(event) {
            if (!event.target.dataset.notclose) {
                this.products = [];
                this.search = '';
            }
        },
        onCloseEsc() {
            this.products = [];
            this.search = '';
        },
    },
};
