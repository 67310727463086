import createKlarnaPaymentsSessionGql from '~/queries/mutations/createKlarnaPaymentsSession.gql';
import isKlarnaPaymentAvailableGql from '~/queries/isKlarnaPaymentAvailable.gql';
import getAvailablePaymentMethodsGql from '@/queries/getAvailablePaymentMethods.gql';

export const actions = {
    async createKlarnaSession() {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        if (!cartId) {
            return;
        }

        const query = createKlarnaPaymentsSessionGql;

        const {
            data: {
                createKlarnaPaymentsSession: { client_token, payment_method_categories },
            },
        } = await client.query({
            query,
            variables: { cartId: cartId },
            fetchPolicy: 'no-cache',
        });

        try {
            Klarna.Payments.init({
                client_token: client_token,
            });
        } catch (e) {
            console.error(e);
        }

        this.$storage.setCookie('klarna_payment_session_token', client_token, { maxAge: 2592000 });
    },

    async loadKlarnaPaymentMethod() {
        try {
            Klarna.Payments.load(
                {
                    container: '#klarna-payments-container',
                    payment_method_categories: ['pay_later', 'pay_over_time'],
                    instance_id: 'klarna-widget',
                },
                {},
                function (res) {
                    if (res.error) {
                        console.error(res);
                    }
                },
            );
        } catch (e) {
            console.error(e);
        }
    },

    async isKlarnaPaymentMethodAvailable() {
        const client = this.app.apolloProvider.defaultClient;
        const {
            data: {
                storeConfig: { is_klarna_payment_enabled },
            },
        } = await client.query({
            query: isKlarnaPaymentAvailableGql,
            fetchPolicy: 'no-cache',
        });

        return is_klarna_payment_enabled === '1';
    },
    async authorizeKlarnaPayment({ dispatch }) {
        const cart = await this.dispatch('cart/getCart');
        const billingAddress = cart.cart.billing_address;
        const shippingAddress = cart.cart.shipping_addresses[0];
        const email = cart.cart.email;
        return await new Promise((resolve, reject) => {
            Klarna.Payments.authorize(
                {
                    container: '#klarna-payments-container',
                    payment_method_categories: ['pay_later', 'pay_over_time'],
                    instance_id: 'klarna-widget',
                },
                {
                    billing_address: {
                        given_name: billingAddress.firstname,
                        family_name: billingAddress.lastname,
                        email: email,
                        postal_code: billingAddress.postcode,
                        street_address: billingAddress.street[0],
                        city: billingAddress.city,
                        phone: billingAddress.telephone,
                        country: billingAddress.country.code,
                    },
                    shipping_address: {
                        given_name: shippingAddress.firstname,
                        family_name: shippingAddress.lastname,
                        email: email,
                        postal_code: shippingAddress.postcode,
                        street_address: shippingAddress.street[0],
                        city: shippingAddress.city,
                        phone: shippingAddress.telephone,
                        country: shippingAddress.country.code,
                    },
                    customer: {
                        email: email,
                        postal_code: billingAddress.postcode,
                        given_name: billingAddress.firstname,
                        family_name: billingAddress.firstname,
                        street_address: billingAddress.street[0],
                        city: billingAddress.city,
                        phone: billingAddress.telephone,
                    },
                },
                function (res) {
                    if (res.error) {
                        reject(new Error(res.error));
                    } else if (res.show_form === 'false' || res.approved === false) {
                        reject(new Error('Payment was not approved'));
                    } else {
                        res.show_form = false;
                        resolve(true);
                    }
                },
            );
        });
    },
    async getAvailablePaymentMethods() {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        if (!cartId) {
            return;
        }

        const query = getAvailablePaymentMethodsGql;

        const {
            data: {
                cart: { available_payment_methods },
            },
        } = await client.query({
            query,
            variables: { id: cartId },
            fetchPolicy: 'no-cache',
        });

        return available_payment_methods;
    },
};
