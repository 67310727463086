//
//
//
//
//
//
//
//
//
//

export default {
    props: ['widget'],
    computed: {
        colors() {
            try {
                switch (this.widget.fields.background) {
                    case 'Dark':
                        return 'bg-[#2D3D47] text-white';
                    case 'Info':
                        return 'bg-info text-white';
                    default:
                        return 'bg-info text-white';
                }
            } catch (e) {
                return 'bg-info text-white';
            }
        },
    },
};
