//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        open: {
            type: Boolean,
            default: false,
            required: false,
        },
        name: {
            type: String,
            default: '',
            required: false,
        },
        buttonClass: {
            type: String,
            default: '',
            required: false,
        },
        openEvent: {
            type: String,
            default: '',
            required: false,
        },
    },
    data() {
        const active = this.open;

        return {
            active,
        };
    },
    mounted() {
        if (this.openEvent) {
            this.$root.$on(
                this.openEvent,
                function () {
                    this.active = true;
                }.bind(this),
            );
        }
    },
    methods: {
        toggle(event) {
            const exclusive = this.$parent.asd;
            this.$parent.$children.forEach((item, index) => {
                if (exclusive) {
                    item.active = false;
                }
                if (item.name === event) {
                    this.active = !this.active;
                }
            });
        },

        startTransition(el) {
            el.style.height = `${el.scrollHeight}px`;
        },

        endTransition(el) {
            el.style.height = '';
        },
    },
};
