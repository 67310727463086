//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            open: false,
            offset: '',
        };
    },
    watch: {
        $route(to, from) {
            this.$store.dispatch('cart/openCart', false);
        },
        cartOpen(val, oldVal) {
            this.get();
            this.$notify.clear();
        },
    },
    computed: {
        ...mapGetters({
            cartOpen: 'cart/open',
        }),
        cartItemsMaxHeight() {
            return `calc(100vh - ${this.offset}px)`;
        },
        amount() {
            try {
                if (this.$store.state.cart.cart.total_quantity) {
                    return this.$store.state.cart.cart.total_quantity;
                }
                if (this.$storage.getCookie('cart_qty')) {
                    return this.$storage.getCookie('cart_qty');
                }
            } catch {
                return '0';
            }
        },
    },
    methods: {
        calculateItemsOffset() {
            try {
                const header = this.$refs.cartHeader;
                const footer = this.$refs.cartFooter;
                const offset = header.clientHeight + footer.clientHeight;
                this.offset = offset;
                return offset;
            } catch (e) {
                return 0;
            }
        },
        async get() {
            await this.$store.dispatch('cart/getCart');
            this.calculateItemsOffset();
        },
    },
};
