import gql from 'graphql-tag';

export default gql`
    mutation createKlarnaPaymentsSession($cartId: String!) {
        createKlarnaPaymentsSession(input: { cart_id: $cartId }) {
            client_token
            payment_method_categories {
                identifier
                name
                asset_urls {
                    descriptive
                    standard
                }
            }
        }
    }
`;
