import query from '@/queries/getCustomerWishlist.gql.js';

export const state = () => ({
    wishlist: {},
    loading: false,
});
export const mutations = {
    set_wishlist(state, value) {
        state.wishlist = value;
    },
    set_loading(state, value) {
        state.loading = value;
    },
};
export const actions = {
    setLoading({ commit }, value) {
        commit('set_loading', value);
    },
    async getWishlist({ commit }) {
        try {
            commit('set_loading', true);
            const client = this.app.apolloProvider.defaultClient;
            const {
                data: {
                    customer: { wishlists },
                },
            } = await client.mutate({
                mutation: query,
            });

            commit('set_wishlist', wishlists[0]);
            commit('set_loading', false);

            return wishlists[0];
        } catch (e) {
            throw new Error(e.message);
        }
    },
    emptyWishlist({ commit }) {
        commit('set_wishlist', {});

        return {};
    },
};
